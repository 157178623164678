export default {
  computed: {
    isDense () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return true
          case 'sm': return false
          case 'md': return false
          case 'lg': return false
          case 'xl': return false
        }
      },
      btnHeight () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return "36px"
          case 'sm': return "56px"
          case 'md': return "56px"
          case 'lg': return "56px"
          case 'xl': return "56px"
        }
      },
  },
  methods: {
  }
};