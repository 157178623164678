import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    receiptForm:null,
    receipt:null
  },
  mutations: {
    SET_RECEIPT(state, receipt) {
      state.receipt = Object.assign({}, receipt)
    },
    SET_RECEIPT_FORM(state, receiptForm) {
      state.receiptForm = Object.assign({}, receiptForm)
    }
  },
  actions: {},
  modules: {},
  getters: {
    getterReceipt: (state) => {
      return state.receipt;
    },
    getterReceiptForm: (state) => {
      return state.receiptForm;
    }
  },
});
