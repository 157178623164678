<template>
  <v-app>
    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({
  }),
};
</script>
<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .dialog-card {width:90%;}
  .info-text {width:90% !important; margin:auto !important}
  .p-breakpoint{font-size:12px !important}
  .v-select__selection--comma{
    font-size: inherit;
  }
  .v-text-field input{
    font-size: inherit;
  }
  .receipt-card-btn{
    font-size: large !important;
  }
  .receipt-content-text{
    font-size:inherit !important
  }
  /* .ignore-p-breakpoint > p{
    font-size: 30px !important;
  } */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .dialog-card {width:600px};
  .info-text{width:600px !important; margin:auto !important}
  .p-breakpoint{font-size:20px !important;}
  .v-select__selection--comma{
    font-size: large !important;
  }
  .v-text-field input{
    font-size: large !important;
  }
  .receipt-card-btn{
    font-size: large !important;
  }
  .receipt-content-text{
    font-size:16px !important
  }
  
  /* .ignore-p-breakpoint > p{
    font-size: 30px !important;
  } */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .dialog-card {width:600px};
  .info-text{width:600px !important; margin:auto !important}
  .p-breakpoint{font-size:20px !important;}
  .v-select__selection--comma{
    font-size: large !important;
  }
  .v-text-field input{
    font-size: large !important;
  }
  .receipt-card-btn{
    font-size: large !important;
  }
  .receipt-content-text{
    font-size:16px !important
  }
  /* .ignore-p-breakpoint > p{
    font-size: 30px !important;
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .dialog-card {width:600px};
  .info-text{width:600px !important; margin:auto !important}
  .p-breakpoint{font-size:20px !important;}
  .v-select__selection--comma{
    font-size: large !important;
  }
  .v-text-field input{
    font-size: large !important;
  }
  .receipt-card-btn{
    font-size: large !important;
  }
  .receipt-content-text{
    font-size:16px !important
  }
  /* .ignore-p-breakpoint > p{
    font-size: 30px !important;
  } */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .dialog-card {width:600px};
  .info-text{width:600px !important; margin:auto !important}
  .p-breakpoint{font-size:20px !important;}
  .v-select__selection--comma{
    font-size: large !important;
  }
  .v-text-field input{
    font-size: large !important;
  }
  .receipt-card-btn{
    font-size: large !important;
  }
  .receipt-content-text{
    font-size:16px !important
  }
  /* .ignore-p-breakpoint > p{
    font-size: 30px !important;
  } */
}

.receipt-card-btn {
  border-radius: 7px;
}
.dialog-card {
  border-radius: 10px;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  -webkit-scrollbar: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-height: 180px;
  margin: auto !important;
}

.font-family-karla {
  font-family: "Karla" !important;
}

.label-steelblue .v-text-field.v-input--dense .v-label {
  color: steelblue !important
}
</style>
